import { ActivatedRoute, Router } from '@angular/router';
import { Component, Inject, OnInit } from '@angular/core';
import {
  Days,
  ExtendedFrequencyPolicy,
  ExtendedFrequencyType,
  FrequencyOccurence,
  TemplateFull,
} from '@al/model';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  FormGroupDirective,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import {
  FormGroupModelInterface,
  GenericFormComponent,
} from '@al/generic-components';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { FrequencyFormNameEnum } from './frequency-form-name.enum';
import { LocalStorageKey } from '@al/local-cache';
import { NavigationService } from '@al/navigation';
import { ReferentielService } from '@al/referentiel';
import { TemplateFullService } from '@al/services';
import { TemplateModelService } from '@al/dnd-service';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment-timezone';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'al-template-cron-details',
  templateUrl: './template-cron-details.component.html',
  styleUrls: ['./template-cron-details.component.scss'],
  providers: [FormGroupDirective],
})
export class TemplateCronDetailsComponent
  extends GenericFormComponent<TemplateFull>
  implements FormGroupModelInterface<TemplateFull>, OnInit
{
  public daysList: Days[] = [];

  public extendedFrequencyType = ExtendedFrequencyType;

  public formControlEnum = FrequencyFormNameEnum;

  public frequency: ExtendedFrequencyPolicy | undefined = undefined;

  public frequencyTypeList = Object.values(ExtendedFrequencyType);

  public occurenciesList: FrequencyOccurence[] = new ReferentielService()
    .frequencyOccurenceList;

  // protected readonly formControlNames = FrequencyFormNameEnum;

  private templateFull: TemplateFull = new TemplateFull();

  public constructor(
    @Inject(MAT_DIALOG_DATA) public data: { form: FormGroup },
    protected override dialog: MatDialog,
    protected override navigationservice: NavigationService,
    protected override formBuilder: FormBuilder,
    protected override genericService: TemplateFullService,
    protected override translateService: TranslateService,
    protected override router: Router,
    protected override route: ActivatedRoute,
    protected templateModelService: TemplateModelService,
    public dialogRef: MatDialogRef<TemplateCronDetailsComponent>
  ) {
    super(
      dialog,
      navigationservice,
      formBuilder,
      genericService,
      translateService,
      router,
      route
    );
    this.daysList = new ReferentielService().daysList;
    // this.form = this.buildFormGroup(this.templateFull);
  }

  public buildFormGroup(entity: TemplateFull | null): FormGroup<any> {
    // throw new Error('Method not implemented.');pa
    let formGroup;
    //FIXED recylage du formulaire, sinon le binding part en cacahuete
    if (this.form) {
      this.clearControl(this.form);

      formGroup = this.form;
    } else {
      let self = this;
      formGroup = this.formBuilder.group(
        {},
        // eslint-disable-next-line @typescript-eslint/unbound-method
        { validators: [self.dateRangeValidator] }
      );
    }
    if (entity) {
      let frequency = entity.extendedFrequencyPolicy;
      if (frequency) {
        formGroup.removeControl(FrequencyFormNameEnum.TYPE);
        formGroup.addControl(
          FrequencyFormNameEnum.TYPE,
          new FormControl(frequency?.type, [])
        );
        this.setFormControl(formGroup);
      }
    }
    // formGroup.markAsUntouched();

    return formGroup;
  }

  // private checkStartDate(control: AbstractControl): ValidationErrors | null {

  public compareDays(option: Days, value: Days): boolean {
    if (option !== null && value !== null) {
      return option.id === value.id;
    }
    return false;
  }

  public compareFrequencyOccurence(
    option: FrequencyOccurence,
    value: FrequencyOccurence
  ): boolean {
    if (option !== null && value !== null) {
      return option.id === value.id;
    }
    return false;
  }

  // public compareFrequencyType(
  //   option: ExtendedFrequencyType,
  //   value: ExtendedFrequencyType,
  // ): boolean {
  //   if (option !== null && value !== null) {
  //     return option.valueOf() === value.valueOf();
  //   }
  //   return false;
  // }

  public dateRangeValidator(): ValidationErrors | null {
    // return (formGroup: AbstractControl): ValidationErrors | null => {
    if (this) {
      if (this.form) {
        const startDate = this.form.get('startDate')?.value;
        const endDate = this.form.get('endDate')?.value;

        // Si l'une des dates n'est pas définie, on ne fait pas de validation
        if (!startDate || !endDate) {
          return null;
        }

        // Conversion des dates en objets Date pour la comparaison
        const start = new Date(startDate);
        const end = new Date(endDate);

        // Vérification que la date de début est avant la date de fin
        if (start > end) {
          return { dateRange: true };
        }
      }
    }

    return null;
    // };
  }

  public getDateTime(value: string): Date {
    let timezone = localStorage.getItem(LocalStorageKey.DEFAULT_TIMEZONE);
    if (timezone) {
      let v = moment.tz(value, timezone);
      return v.toDate();
    }
    return new Date();
  }

  public getEntityToSave(): TemplateFull {
    if (this.frequency && this.form !== null) {
      if (!this.form.get(FrequencyFormNameEnum.USE_DATE_FILTER)) {
        this.frequency.useDateFilter = false;
      } else {
        this.frequency.useDateFilter = this.form.get(
          FrequencyFormNameEnum.USE_DATE_FILTER
        )?.value;
      }

      if (!this.form.get(FrequencyFormNameEnum.USE_DAY_FILTER)) {
        this.frequency.useDayFilter = false;
      } else {
        this.frequency.useDayFilter = this.form.get(
          FrequencyFormNameEnum.USE_DAY_FILTER
        )?.value;
      }

      if (!this.form.get(FrequencyFormNameEnum.USE_TIME_FILTER)) {
        this.frequency.useTimeFilter = false;
      } else {
        this.frequency.useTimeFilter = this.form.get(
          FrequencyFormNameEnum.USE_TIME_FILTER
        )?.value;
      }

      this.frequency.type = this.form.get(FrequencyFormNameEnum.TYPE)?.value;
      if (this.form.get(FrequencyFormNameEnum.START_DATE)?.value) {
        this.frequency.startDate = this.getDateTime(
          this.form.get(FrequencyFormNameEnum.START_DATE)?.value
        );
      }
      if (this.form.get(FrequencyFormNameEnum.END_DATE)?.value) {
        this.frequency.endDate = this.getDateTime(
          this.form.get(FrequencyFormNameEnum.END_DATE)?.value
        );
      } else {
        this.frequency.endDate = null;
      }
      if (
        this.form.get(FrequencyFormNameEnum.APPLICABLE_DAYS) &&
        this.form.get(FrequencyFormNameEnum.APPLICABLE_DAYS)?.value
      ) {
        let days: any[] = this.form.get(
          FrequencyFormNameEnum.APPLICABLE_DAYS
        )?.value;
        this.frequency!.daysOfTheWeek = [];
        days.forEach((day) => {
          this.frequency!.daysOfTheWeek.push(day);
        });
      } else {
        this.frequency.daysOfTheWeek = [];
      }

      this.frequency.startExecutionTime = this.form.get(
        FrequencyFormNameEnum.START_EXECUTION_TIME
      )?.value;
      this.frequency.endExecutionTime = this.form.get(
        FrequencyFormNameEnum.END_EXECUTION_TIME
      )?.value;

      this.frequency.executionTime = this.form.get(
        FrequencyFormNameEnum.EXECUTION_TIME
      )?.value;

      // this.frequency.executionDate = this.form.get(
      //   FrequencyFormNameEnum.ONE_SHOT_EXECUTION_TIME
      // )?.value;
      if (this.form.get(FrequencyFormNameEnum.ONE_SHOT_EXECUTION_TIME)?.value) {
        this.frequency.executionDate = this.getDateTime(
          this.form.get(FrequencyFormNameEnum.ONE_SHOT_EXECUTION_TIME)?.value
        );
      } else {
        this.frequency.executionDate = null;
      }
      this.frequency.interval = this.form.get(
        FrequencyFormNameEnum.INTERVAL
      )?.value;
      this.frequency.occurency = this.form.get(
        FrequencyFormNameEnum.OCCURENCY
      )?.value;
      this.frequency.executionDay = this.form.get(
        FrequencyFormNameEnum.DAY
      )?.value;
    }
    this.templateFull.extendedFrequencyPolicy = this.frequency!;
    return this.templateFull;
  }

  public initFormGroup(entity: TemplateFull): void {
    this.buildFormGroup(entity);
  }

  public initModel(): TemplateFull {
    return new TemplateFull();
  }

  public ngOnInit(): void {
    this.templateModelService
      .getTemplateFullObservable()
      // .pipe(takeUntil(this.ngUnsubscribe))
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((templateFull: TemplateFull) => {
        if (templateFull.frequency) {
          this.templateFull = templateFull;
          if (!templateFull.extendedFrequencyPolicy) {
            templateFull.extendedFrequencyPolicy =
              new ExtendedFrequencyPolicy();
          }

          this.frequency = templateFull.extendedFrequencyPolicy
            ? templateFull.extendedFrequencyPolicy!
            : new ExtendedFrequencyPolicy();
          this.buildFormGroup(templateFull);
          this.data.form.removeControl('template-extended-frequency');
          this.data.form.addControl('template-extended-frequency', this.form);
        }
      });
  }

  public save(): void {
    //
    let model = this.getEntityToSave();
    this.templateModelService.updateFrequency(model.extendedFrequencyPolicy);
    this.dialogRef.close();
  }

  /**
   * This method is responsible for managing different control types of the given form based on frequency type.
   * It first removes the current controls of the form to set up new ones specific to the frequency type in consciousness.
   * Frequency type options include hourly, daily, weekly, monthly by date or day, one shot (one-time) execution, or every specific days, weeks, or months.
   *
   * @param {FormGroup} form - The form whose control type is to be set.
   */
  public setFormControl(form: FormGroup): void {
    // logs whenever the control type changes
    console.log('changement de type de controle');
    // clear all existing controls in the form
    this.clearControl(form);
    /*     this.setMandatoryDate(false);
     */ // check if the form has frequency type field
    if (form.get(FrequencyFormNameEnum.TYPE)) {
      // fetch the value of the frequency type
      const frequencyType = form.get(FrequencyFormNameEnum.TYPE)!.value;
      // depending on the current frequency type different controls will be set up
      switch (frequencyType) {
        // if the frequency type is 'hourly'
        case ExtendedFrequencyType.HOURLY:
          // this.setMandatoryDate(true);
          this.useTimeFilter(form, this.frequency!);
          this.useDateFilter(form, this.frequency!);
          this.useDayFilter(form, this.frequency!);
          this.setInterval(form, 23);
          break;
        // if the frequency type is 'daily'
        case ExtendedFrequencyType.DAILY:
          // this.useTimeFilter(form, this.frequency!);
          this.useDateFilter(form, this.frequency!);
          this.useDayFilter(form, this.frequency!);
          this.setExecutionTime(form, this.frequency!);
          // this.setExecutionTime(form, this.frequency!);
          break;
        // and so on for the rest of the frequency types
        case ExtendedFrequencyType.MONTHLY_DAY:
          this.useDateFilter(form, this.frequency!);
          this.useOccurencyAndDay(form, this.frequency!);
          this.setExecutionTime(form, this.frequency!);
          break;
        case ExtendedFrequencyType.WEEKLY:
          // this.useTimeFilter(form, this.frequency!);
          this.useDateFilter(form, this.frequency!);
          // this.useDayFilter(form, this.frequency!);
          this.setExecutionTime(form, this.frequency!);
          this.useDayOnly(form, this.frequency!);
          break;
        case ExtendedFrequencyType.MONTHLY_DATE:
          this.setExecutionTime(form, this.frequency!);
          this.useDateFilter(form, this.frequency!);
          break;
        case ExtendedFrequencyType.ONE_SHOT:
          this.setOneShotExecutionTime(form, this.frequency!);
          break;
        case ExtendedFrequencyType.EVERY_X_DAYS:
          this.useDateFilter(form, this.frequency!);
          this.useDayFilter(form, this.frequency!);
          this.setInterval(form, 30);
          this.setExecutionTime(form, this.frequency!);
          break;

        case ExtendedFrequencyType.EVERY_X_WEEKS:
          this.useDateFilter(form, this.frequency!);
          this.useDayFilter(form, this.frequency!);
          this.setInterval(form, 5);
          this.setExecutionTime(form, this.frequency!);
          break;

        case ExtendedFrequencyType.EVERY_X_MONTHS:
          // this.useTimeFilter(form, this.frequency!);
          this.useDateFilter(form, this.frequency!);
          this.useDayFilter(form, this.frequency!);
          this.setInterval(form, 12);
          this.setExecutionTime(form, this.frequency!);

          break;
        default:
          break;
      }
      form.markAllAsTouched();
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public setValue(formControlName: FrequencyFormNameEnum): void {}

  public toggleDay(checked: boolean) {
    if (this.frequency) {
      if (checked) {
        this.setApplicableDays(this.form, this.frequency);
      } else {
        this.removeApplicableDays(this.form);
      }
      this.form.removeControl(FrequencyFormNameEnum.USE_DAY_FILTER);
      this.form.addControl(
        FrequencyFormNameEnum.USE_DAY_FILTER,
        new FormControl(checked, [])
      );
    }
  }

  public toggleTime(checked: boolean) {
    if (this.frequency) {
      if (checked) {
        this.setTime(this.form, this.frequency);
      } else {
        this.removeTime(this.form);
      }
      this.form.removeControl(FrequencyFormNameEnum.USE_TIME_FILTER);
      this.form.addControl(
        FrequencyFormNameEnum.USE_TIME_FILTER,
        new FormControl(checked, [])
      );
    }
  }

  /**
   * The `clearControl` method is a helper function that's used to remove
   * a set of controls from a given FormGroup. These controls are dictated
   * by elements of the `FrequencyFormNameEnum`. In the current implementation,
   * this function is eliminating all the `FormControl` instances from the form,
   * essentially resetting it.
   *
   * This is particularly useful when you want to reset the form and add new
   * controls according to a different context or user input.
   *
   * @remarks
   * The method makes use of the `removeControl` method from the Angular
   * FormGroup API to remove the controls.
   *
   * @param form - The FormGroup instance from which to remove the controls
   */
  private clearControl(form: FormGroup) {
    form.removeControl(FrequencyFormNameEnum.START_DATE);
    form.removeControl(FrequencyFormNameEnum.START_DATE);
    form.removeControl(FrequencyFormNameEnum.START_DATE);
    form.removeControl(FrequencyFormNameEnum.START_EXECUTION_TIME);
    form.removeControl(FrequencyFormNameEnum.END_EXECUTION_TIME);
    form.removeControl(FrequencyFormNameEnum.EXECUTION_TIME);
    form.removeControl(FrequencyFormNameEnum.INTERVAL);
    form.removeControl(FrequencyFormNameEnum.DAY);
    form.removeControl(FrequencyFormNameEnum.OCCURENCY);

    form.removeControl(FrequencyFormNameEnum.USE_TIME_FILTER);
    form.removeControl(FrequencyFormNameEnum.APPLICABLE_DAYS);
    form.removeControl(FrequencyFormNameEnum.USE_DAY_FILTER);
    form.removeControl(FrequencyFormNameEnum.USE_DATE_FILTER);
    form.removeControl(FrequencyFormNameEnum.ONE_SHOT_EXECUTION_TIME);
  }

  // private checkEndDate(control: AbstractControl): ValidationErrors | null {
  //   if (
  //     this.form.get(FrequencyFormNameEnum.USE_DATE_FILTER) &&
  //     this.form.get(FrequencyFormNameEnum.USE_DATE_FILTER)!.value
  //   ) {
  //     if (
  //       this &&
  //       this.form &&
  //       this.form.get(FrequencyFormNameEnum.START_DATE)
  //     ) {
  //       let startTime: string = this.form.get(
  //         FrequencyFormNameEnum.START_DATE,
  //       )!.value;
  //       if (startTime && this.form.get(FrequencyFormNameEnum.END_DATE)) {
  //         let endTime: string = this.form.get(
  //           FrequencyFormNameEnum.END_DATE,
  //         )!.value;
  //
  //         if (endTime && startTime.localeCompare(endTime) > -1) {
  //           return { endDate: { value: control.value } };
  //         }
  //       }
  //     }
  //   }
  //   return null;
  // }

  private removeApplicableDays(form: FormGroup) {
    form.removeControl(FrequencyFormNameEnum.APPLICABLE_DAYS);
  }

  private removeDate(form: FormGroup) {
    if (form) {
      form.removeControl(FrequencyFormNameEnum.START_DATE);
      form.removeControl(FrequencyFormNameEnum.END_DATE);
    }
  }

  private removeTime(form: FormGroup) {
    if (form) {
      form.removeControl(FrequencyFormNameEnum.START_EXECUTION_TIME);
      form.removeControl(FrequencyFormNameEnum.EXECUTION_TIME);
      form.removeControl(FrequencyFormNameEnum.END_EXECUTION_TIME);
    }
  }

  private setApplicableDays(
    formGroup: FormGroup,
    frequency: ExtendedFrequencyPolicy
  ) {
    if (formGroup.get(this.formControlEnum.USE_DAY_FILTER)?.value) {
      formGroup.addControl(
        this.formControlEnum.APPLICABLE_DAYS,
        new FormControl(frequency.daysOfTheWeek, [Validators.required])
      );
    }
  }

  private setDate(formGroup: FormGroup, frequency: ExtendedFrequencyPolicy) {
    let timezone = localStorage.getItem(LocalStorageKey.DEFAULT_TIMEZONE);
    if (frequency) {
      formGroup.addControl(
        FrequencyFormNameEnum.START_DATE,
        new FormControl(
          moment(frequency?.startDate?.getTime())
            .tz(timezone!)
            .format('yyyy-MM-DD'),
          [Validators.required]
        )
      );

      if (frequency?.endDate) {
        formGroup.addControl(
          FrequencyFormNameEnum.END_DATE,
          new FormControl(
            moment(frequency?.endDate?.getTime())
              .tz(timezone!)
              .format('yyyy-MM-DD'),
            []
          )
        );
      } else {
        formGroup.addControl(
          FrequencyFormNameEnum.END_DATE,
          new FormControl(frequency?.endDate, [])
        );
      }
    }
  }

  private setExecutionTime(
    form: FormGroup,
    frequency: ExtendedFrequencyPolicy
  ) {
    form.addControl(
      FrequencyFormNameEnum.EXECUTION_TIME,
      new FormControl(frequency.executionTime, [Validators.required])
    );
  }

  private setInterval(form: FormGroup, maxValue: number) {
    form.addControl(
      FrequencyFormNameEnum.INTERVAL,
      new FormControl(this.frequency?.interval ? this.frequency?.interval : 1, [
        Validators.min(1),
        Validators.max(maxValue),
        Validators.required,
      ])
    );
  }

  private setOneShotExecutionTime(
    form: FormGroup,
    frequency: ExtendedFrequencyPolicy
  ) {
    let timezone = localStorage.getItem(LocalStorageKey.DEFAULT_TIMEZONE);
    if (form) {
      form.addControl(
        FrequencyFormNameEnum.ONE_SHOT_EXECUTION_TIME,
        new FormControl(
          moment(frequency?.startDate?.getTime())
            .tz(timezone!)
            .format('yyyy-MM-DDTHH:mm'),
          // eslint-disable-next-line @typescript-eslint/unbound-method
          [Validators.required]
        )
      );
    }
  }

  private setTime(
    formGroup: FormGroup<{}>,
    frequency: ExtendedFrequencyPolicy
  ) {
    if (formGroup.get(FrequencyFormNameEnum.USE_TIME_FILTER)?.value) {
      formGroup.addControl(
        FrequencyFormNameEnum.START_EXECUTION_TIME,
        new FormControl(frequency.startExecutionTime, [Validators.required])
      );
      formGroup.addControl(
        FrequencyFormNameEnum.END_EXECUTION_TIME,
        new FormControl(frequency.endExecutionTime, [Validators.required])
      );
    }
    /*els  e {
      formGroup.addControl(
        FrequencyFormNameEnum.START_EXECUTION_TIME,
        new FormControl(frequency.startExecutionTime, []),
      );
      formGroup.addControl(
        FrequencyFormNameEnum.END_EXECUTION_TIME,
        new FormControl(frequency.endExecutionTime, []),
      );
    } */
  }

  /**
   * This function is part of the TemplateCronDetailsComponent class. It's used to add a 'USE_DATE_FILTER'
   * FormControl and set date related controls to a provided FormGroup based on the ExtendedFrequencyPolicy object.
   *
   * @param {FormGroup<{}>} formGroup - The FormGroup instance to which the control should be added
   * @param {ExtendedFrequencyPolicy} frequency - The object that provides the initial values for the form fields
   */
  private useDateFilter(
    formGroup: FormGroup<{}>,
    frequency: ExtendedFrequencyPolicy
  ) {
    formGroup.addControl(
      FrequencyFormNameEnum.USE_DATE_FILTER,
      new FormControl(true, [])
    );
    this.setDate(formGroup, frequency);
  }

  /**
   * This method includes the `USE_DAY_FILTER` control in the given form group
   * based on the parameter frequency's `useDateFilter` property.
   * Additionally, it sets the applicable days for that frequency in the form
   * by calling another method `setApplicableDays`.
   *
   * @param formGroup - A given form group that encapsulates the value of a
   *                    group of form control as an instance of FormGroup.
   * @param frequency - An instance that carries metadata within its properties about
   *                    ExtendedFrequencyPolicy that are used to configure the given form group.
   */
  private useDayFilter(
    formGroup: FormGroup<{}>,
    frequency: ExtendedFrequencyPolicy
  ) {
    //New FormControl is added to the FormGroup which captures the state of the form
    formGroup.addControl(
      FrequencyFormNameEnum.USE_DAY_FILTER,
      new FormControl(frequency?.useDayFilter, [])
    );

    //This method is called to set the applicable days checkboxes in the form group
    this.setApplicableDays(formGroup, frequency);
  }

  private useDayOnly(form: FormGroup, frequency: ExtendedFrequencyPolicy) {
    form.addControl(
      FrequencyFormNameEnum.DAY,
      new FormControl(frequency?.executionDay, [Validators.required])
    );
  }

  private useOccurencyAndDay(
    form: FormGroup,
    frequency: ExtendedFrequencyPolicy
  ) {
    form.addControl(
      FrequencyFormNameEnum.OCCURENCY,
      new FormControl(frequency?.occurency, [Validators.required])
    );
    form.addControl(
      FrequencyFormNameEnum.DAY,
      new FormControl(frequency?.executionDay, [Validators.required])
    );
  }

  /**
   * Synchronizes form controls related to the time filter with the provided time policy model.
   *
   * @param {FormGroup<{}>} formGroup - The form group that needs new controls to be added to.
   * @param {ExtendedFrequencyPolicy} frequency - The policy model that provides initial data.
   */
  private useTimeFilter(
    formGroup: FormGroup<{}>,
    frequency: ExtendedFrequencyPolicy
  ) {
    // Adds a form control that designates the use of the time filter,
    // with its initial value set based on the provided model.
    formGroup.addControl(
      FrequencyFormNameEnum.USE_TIME_FILTER,
      new FormControl(frequency?.useTimeFilter, [])
    );

    // Creates and adds controls for start and end execution times,
    // initialized with values from the model.
    this.setTime(formGroup, frequency);
  }

  /*   protected readonly ExtendedFrequencyPolicy = ExtendedFrequencyPolicy;
   */
  /*   get getTimeFilter(): boolean {
    return true; //this.frequency ? this.frequency?.useTimeFilter : false;
    // return this.frequency ? this.frequency?.useTimeFilter : false;
  }
 */
}
